import { theme } from "@ifgengineering/component-library";
import styled from "styled-components";

export const ZakatTopupContainer = styled.div`
  max-width: 560px;
  margin: 0 auto;
`;

export const HeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-bottom: 3em;
  & > p {
    width: 85%;
    color: ${theme.colors.SLATE700};
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > div {
    padding: 2em 1em;
    text-align: center;
  }
  & > div:nth-child(1) {
    background-color: ${theme.colors.SLATE100};
    border-radius: 0.5em;
  }
  & > div:nth-child(2) {
    & > button > h2 {
      font-weight: 500;
    }
  }
`;

export const InputContainer = styled.div`
  margin-bottom: 3em;
  & > div > p {
    font-weight: 400;
  }
`;
